<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column">
        <b-row>
          <b-col cols="12" md="6">
            <h4>New Hire Request Details:</h4>
          </b-col>
          <b-col cols="12" md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="float-right"
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>Back To list</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Raised By</span>
              </b-col>
              <b-col>
                <b-link
                  v-if="$can('employee_show')"
                  :to="{
                    name: 'view-employee',
                    params: { id: userData.raised_by_hashid },
                  }"
                >
                  {{ userData.raised_by_label }}
                </b-link>
                <p v-else>
                  {{ userData.raised_by_label }}
                </p>
              </b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">Role</span>
              </b-col>
              <b-col>
                {{ userData.role }}
              </b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="MailIcon" class="mr-75" />
                <span class="font-weight-bold">Team</span>
              </b-col>
              <b-col>
                {{ userData.team }}
              </b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="StarIcon" class="mr-75" />
                <span class="font-weight-bold">Experience</span>
              </b-col>
              <b-col>
                {{ userData.experience_required }}
              </b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="FileIcon" class="mr-75" />
                <span class="font-weight-bold">Skills</span>
              </b-col>
              <b-col>
                {{ userData.skills_required }}
              </b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">New Hire Request Date</span>
              </b-col>
              <b-col>
                {{ userData.nhr_date | formatDate }}
              </b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">FullTime / Contract</span>
              </b-col>
              <b-col>
                {{ userData.period_of_work_label }}
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row class="mb-1 align-items-center">
              <b-col>
                &#8377;
                <span class="font-weight-bold">Billable / Non-Billable</span>
              </b-col>
              <b-col>
                {{ userData.billing_type_label }}
              </b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="FileIcon" class="mr-75" />
                <span class="font-weight-bold">No Of Positions</span></b-col
              >
              <b-col> {{ userData.no_of_positions }}</b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col>
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold"
                  >New Hire Request Status</span
                ></b-col
              >
              <b-col>
                <v-select
                  v-if="false"
                  v-model="userData.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="nhrStatusOption"
                  :reduce="(label) => label.code"
                  label="label"
                  :clearable="false"
                  @input="changeStatus(userData, userData.status)"
                  :searchable="false"
                  placeholder="Select Status.."
                  class=""
                />
                <div v-else>
                  {{ userData.status_label }}
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-1 align-items-center">
              <b-col
                ><feather-icon icon="GlobeIcon" class="mr-75" />
                <span class="font-weight-bold">Replacement Type</span></b-col
              >
              <b-col> {{ userData.requirement_type_label }}</b-col>
            </b-row>
            <b-row class="mb-1 align-items-center">
              <b-col
                ><feather-icon icon="FileIcon" class="mr-75" />
                <span class="font-weight-bold">NHR Attachments</span></b-col
              >
              <b-col>
                <feather-icon
                  v-b-tooltip.hover.top="'View Attachments'"
                  class="btn-link cursor-pointer ml-1"
                  icon="EyeIcon"
                  @click.stop.prevent="openWindow(userData.file)"
              /></b-col>
            </b-row>
            <b-row
              class="mb-1 align-items-center"
              v-if="userData.requirement_type === 1"
            >
              <b-col>
                <feather-icon icon="FileIcon" class="mr-75" />
                <span class="font-weight-bold">Replacement Employee</span>
              </b-col>
              <b-col> {{ userData.replacing_label }} </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mb-1 align-items-center">
          <b-col cols="12">
            <feather-icon icon="GlobeIcon" class="mr-75" />
            <span class="font-weight-bold">Job Description</span></b-col
          >
          <b-col cols="12" class="mt-1">{{ userData.job_description }}</b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nhrStatusOption: [
        { label: "Approval Pending", code: 0 },
        { label: "Request Approved", code: 1 },
        { label: "In Progress", code: 2 },
        { label: "Request Completed", code: 3 },
      ],
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },
    changeStatus(nhrData, status) {
      this.$store
        .dispatch("app-nhr/updateNHRStatus", {
          nhrData,
        })
        .then((res) => {
          if (res.data.success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Hire Request Updated",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Hire Request updating Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Candidate updating Failed",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
  },
};
</script>

<style>
</style>
